import {CSSStyles} from '../../../types/CSSStyles';

import {css} from '@emotion/react';
import useMergedRef from '@react-hook/merged-ref';
import React, {forwardRef, useRef} from 'react';
import {AriaButtonOptions, useButton} from 'react-aria';

export interface Props extends AriaButtonOptions<'button'> {
  readonly children?: React.ReactNode;
  readonly sx?: CSSStyles;
}

function FlatButton(
  props: Props,
  parentRef: React.ForwardedRef<unknown>,
): JSX.Element {
  const {children, sx, ...otherProps} = props;
  const ref = useRef(null);
  const mergedRef = useMergedRef(parentRef, ref);
  const {buttonProps} = useButton(otherProps, ref);
  const isDisabled = buttonProps.disabled;

  return (
    <button
      css={[
        css({
          display: 'flex',
          alignItems: 'center',
          opacity: isDisabled ? 0.6 : 1,
          boxSizing: 'border-box',
          background: 'none',
          border: 'none',
          cursor: isDisabled ? 'initial' : 'pointer',
          padding: 0,
        }),
        sx,
      ]}
      ref={mergedRef}
      type="button"
      {...buttonProps}
    >
      {children}
    </button>
  );
}

export default forwardRef(FlatButton);
