import React, {useContext} from 'react';

export interface ViewerIDContextValue {
  readonly id: Maybe<string>;
}

export const ViewerIDContext = React.createContext<ViewerIDContextValue>({
  id: null,
});

export default function useViewerID(): Maybe<string> {
  return useContext(ViewerIDContext).id;
}
